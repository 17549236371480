import React from "react";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import Link from "components/Link";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
}) => {
  return (
    <Link
      href="/"
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
    >
      <img style={{objectFit: 'cover'}} src="/airplane_light.png" alt="logo" className="hidden dark:block" width={50} height={50} />
      <img style={{objectFit: 'cover'}} src="/airplane.png" alt="logo" className="block dark:hidden" width={50} height={50} />
    </Link>
  );
};

export default Logo;
