import React, {useEffect} from "react";
import Image from "components/Image";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import {PostDataType} from "../../../../data/types";
import {useNavigate, useParams} from "react-router-dom";
import {ContentfulBlogRepository} from "../../../../utils/blogRepository";
import {PropagateLoader} from "react-spinners";
import {Helmet} from "react-helmet-async";
import {client} from "../../../../utils/amplitudeClient";

const PageSingleTemplate3 = () => {
  const {slug} = useParams<{slug: string}>();
  const [post, setPost] = React.useState<PostDataType>();
  const [relatedPosts, setRelatedPosts] = React.useState<PostDataType[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const router = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    client.logEvent("Post page viewed", {
      project: "hong-kong-dreaming",
      slug,
    });
    if (!slug) {
      router("/404");
      return;
    }
    ContentfulBlogRepository.findPostBySlug(slug).then((post) => {
      setIsLoading(false);
      if (!post) {
        router("/404");
        return;
      }
      setPost(post);
      window.scrollTo(0, 0);
      if (post.categories.length !== 0) {
        const tag = post?.categories[0].id ?? "hongkong-aktywnie"
        ContentfulBlogRepository.findPostsByTag(tag.toString(), 1, 10, "-fields.publishedAt").then((posts) => {
          setRelatedPosts(posts.filter((item) => {
            return item.id !== post.id
          }))
        }).catch((e) => {
          console.log(e);
        });
      }
    }).catch((e) => {
      console.log(e);
      setIsLoading(false);
    });
  }, [slug, router]);

  return (
    <Layout isLoading={isLoading} data={post} related={relatedPosts}>
      {isLoading ? (
          <div className="flex flex-row justify-center mt-24" style={{height: 800}}>
            <PropagateLoader color="rgb(17, 24, 39)" loading={isLoading} size={20} speedMultiplier={1.5} />
          </div>
      ) : (
          <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-black">
            <Helmet>
              <title>{post?.title}</title>
              <meta property="og:title" content={post?.title} />
              <meta name="description" content={post?.desc} />
              <meta property="og:description" content={post?.desc} />
              <meta property="og:image" content={post?.featuredImage} />
              <meta property="keywords" content={post?.seoTags?.join(", ")} />
            </Helmet>
            {/* SINGLE HEADER */}
            <div className="dark container relative z-10">
              <div className="max-w-screen-md">
                <SingleHeader hiddenDesc pageData={post}/>
              </div>
            </div>

            {/* FEATURED IMAGE */}
            <div className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
              <div
                  className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-neutral-900 dark:from-black bg-gradient-to-r"></div>
              <Image
                  className="block w-full h-full object-cover"
                  src={post?.featuredImage}
                  alt=""
                  width={1635}
                  height={774}
                  sizes="(max-width: 1024px) 100vw, 1240px"
              />
            </div>
          </header>
      )}
    </Layout>
  );
};

export default PageSingleTemplate3;
