import React from "react";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import {CookieConsent} from "react-cookie-consent";
import {client} from "../../utils/amplitudeClient";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  return (
    <div className="nc-Footer relative py-16 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 flex items-center lg:col-span-3">
            <SocialsList1 className="flex items-center space-x-3" />
          </div>
        </div>
      </div>
      <CookieConsent
          location="bottom"
          buttonText="Ok, rozumiem!"
          cookieName="legal-consent"
          onAccept={() => client.logEvent("Cookie consent accepted", { project: "hong-kong-dreaming" })}
          style={{ background: "rgba(var(--c-neutral-900), 0.95)" }}
          buttonStyle={{ color: "white", backgroundColor: "rgba(var(--c-primary-600), var(--tw-bg-opacity))", fontSize: "13px" }}
          expires={150}
      >
          Używamy plików cookie, aby poprawić Twoje doświadczenia na naszej stronie. Przeglądając tę stronę, zgadzasz się na użycie plików cookie.{" "}
      </CookieConsent>
    </div>
  );
};

export default Footer;
