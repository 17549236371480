import { Route } from "routers/types";
import { Document } from "@contentful/rich-text-types";

//  ######  CustomLink  ######## //
export interface CustomLink {
  label: string;
  href: Route;
  targetBlank?: boolean;
}

//  ##########  PostDataType ######## //
export interface TaxonomyType {
  id: string | number;
  name: string;
  href: Route;
  count?: number;
  thumbnail?: string;
  desc?: string;
  color?: TwMainColor | string;
  taxonomy: "category" | "tag";
}

export interface Book {
  title: string,
  description: Document,
  photoUrls: string[],
  videoUrl: string
}

export interface PostAuthorType {
  id: string | number;
  firstName: string;
  lastName: string;
  displayName: string;
  avatar: string;
  bgImage?: string;
  email?: string;
  count: number;
  desc: Document;
  jobName: string;
  href: Route;
}

export interface PostDataType {
  id: string | number;
  author: PostAuthorType;
  date: string;
  href: Route;
  disqusUrl?: string;
  categories: TaxonomyType[];
  title: string;
  featuredImage: string;
  desc?: string;
  content?: Document;
  like: {
    count: number;
    isLiked: boolean;
  };
  bookmark: {
    count: number;
    isBookmarked: boolean;
  };
  commentCount: number;
  viewdCount: number;
  readingTime: number;
  postType: "standard" | "video" | "gallery" | "audio";
  videoUrl?: string;
  audioUrl?: string | string[];
  galleryImgs?: string[];
  seoTags?: string[];
}

export type TwMainColor =
  | "pink"
  | "green"
  | "yellow"
  | "red"
  | "indigo"
  | "blue"
  | "purple"
  | "gray";

export const randomColor = (by?: string): TwMainColor => {
  if (by !== undefined) {
    const colors = new Map<string, TwMainColor>([
      ["hongkong", "pink"],
      ["miejsca", "indigo"],
    ]);

    if (colors.has(by)) {
      return colors.get(by) as TwMainColor;
    }
  }

  const colors: TwMainColor[] = [
    "pink",
    "green",
    "yellow",
    "red",
    "indigo",
    "blue",
    "purple",
    "gray",
  ];

  return colors[Math.floor(Math.random() * colors.length)];
}
