import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page } from "./types";
import PageHome from "app/(home)/page";
import PageAuthor from "app/author/page";
import PageSingleTemplate3 from "app/(singles)/(has-sidebar)/single-3/page";
import PageSearch from "app/(search)/search/page";
import Page404 from "app/not-found";
import SiteHeader from "app/SiteHeader";
import Footer from "../components/Footer/Footer";
import InMediaPage from "../app/author/inMediaPage";
import BookPage from "../app/author/bookPage";

export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/#", component: PageHome },
  { path: "/autorzy/:slug", component: PageAuthor },
  { path: "/:slug", component: PageSingleTemplate3 },
  { path: "/autorzy-w-mediach/:slug", component: InMediaPage },
  { path: "/ksiazki/:slug", component: BookPage },
  { path: "/szukaj", component: PageSearch },
  {path: "/404", component: Page404},
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <SiteHeader />

      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
