import React, {useEffect, useState} from "react";
import SectionLargeSlider from "app/(home)/SectionLargeSlider";
import {client} from "../../utils/amplitudeClient";
import {PostDataType} from "../../data/types";
import {ContentfulBlogRepository, SearchResult} from "../../utils/blogRepository";
import Card11 from "../../components/Card11/Card11";
import Heading from "../../components/Heading/Heading";
import SectionMagazine1 from "../../components/Sections/SectionMagazine1";

interface PaginationState {
    page: number;
    limit: number;
}

const PageHome = () => {
  const [featuredPosts, setFeaturedPosts] = useState<PostDataType[]>([]);
  const [searchResult, setSearchResult] = useState<SearchResult>({posts: [], pages: 0, total: 0});
  const [pagination, setPagination] = useState<PaginationState>({
    page: 1,
    limit: 12
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    client.logEvent("Home page viewed", {project: "hong-kong-dreaming"})
    ContentfulBlogRepository.findFeaturedPosts("home-page", 1, 10, "fields.featuredOrder").then((posts) => {
      setFeaturedPosts(posts)
    }).catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    ContentfulBlogRepository.findAllPosts(pagination.page, pagination.limit, '-fields.publishedAt')
        .then((result) => {
          setSearchResult(result)
        }).catch((e) => console.log(e));
  }, [pagination]);

  const handlePaginationSwitch = (page: number) => {
    const commentDiv = document.getElementById('section-all-posts');
    if (commentDiv) {
      commentDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    client.logEvent("Home page pagination switched", {
      project: "hong-kong-dreaming",
      currentPage: pagination.page,
      newPage: page
    });
    setPagination({
      ...pagination,
      page
    })
  }

  const renderPagination = (searchResults: SearchResult, pagination: PaginationState) => {
    const pages = []

    for(let i = 1; i <= searchResults.pages; i++) {
      if (pagination.page === i) {
        pages.push(
            <span
                key={i}
                className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white`}
                onClick={() => {handlePaginationSwitch(i)}}
                style={{userSelect: 'none'}}
                data-page={i}
            >
            {i}
          </span>
        )
      } else {
        pages.push(
            <span
                key={i}
                className={`hover:cursor-pointer inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700`}
                onClick={() => {handlePaginationSwitch(i)}}
                style={{userSelect: 'none'}}
                data-page={i}
            >
            {i}
          </span>
        )
      }
    }

    if (pages.length === 1) {
      return <nav/>
    }

    return <nav
        className={`nc-Pagination inline-flex space-x-1 text-base font-medium`}
    >
      {pages}
    </nav>
  };

  return (
    <div className="nc-PageHome relative">
      <div className="container relative">
        <SectionLargeSlider
            className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
            heading={""}
            posts={featuredPosts}
        />

        <SectionMagazine1 className="py-16 lg:py-28" posts={[]} />

        <Heading id={"section-all-posts"} desc={""}>Wszystkie wpisy 🎨</Heading>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
          {searchResult.posts.map((post) => (
              <Card11 key={post.id} post={post}/>
          ))}
        </div>

        {/* PAGINATION */}
        <div
            className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center mb-10">
          {renderPagination(searchResult, pagination)}
        </div>

      </div>
    </div>
  );
};

export default PageHome;
