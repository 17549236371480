import React, {FC, useEffect, useState} from "react";
import {Book} from "../../data/types";
import {useNavigate, useParams} from "react-router-dom";
import ReactPlayer from "react-player";
import NcImage from "../../components/NcImage/NcImage";
import ListingImageGallery from "../../components/listing-image-gallery/ListingImageGallery";
import {ContentfulBlogRepository} from "../../utils/blogRepository";
import {PropagateLoader} from "react-spinners";
import {contentfulDocumentToReactComponents} from "../../utils/contentfulDocumentToReactComponents";
import {Helmet} from "react-helmet-async";
import {client} from "../../utils/amplitudeClient";

export interface Props {
    className?: string;
}

const BookPage: FC<Props> = ({className = ""}) => {
    const {slug} = useParams<{ slug: string }>();
    const [isOpen, setIsOpen] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [book, setBook] = useState<Book | null>(null);
    const router = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        client.logEvent("Book page viewed", {
            project: "hong-kong-dreaming",
        });
        const loadBook = () => {
            if (!slug) {
                setIsLoading(false);
                router("/404");
                return;
            }
            setIsLoading(true);
            ContentfulBlogRepository.findAuthorBookBySlug(slug)
                .then((book) => {
                    if (!book) {
                        setIsLoading(false);
                        router("/404");
                        return;
                    }
                    setBook(book);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error("fetchAuthorBook -> error", error);
                    setIsLoading(false);
                });
        }
        loadBook();
    }, [slug, router]);

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
    };
    const handleCloseModal = () => setIsOpen(false);
    if (isLoading) {
        return (
            <div className="flex flex-row justify-center mt-24" style={{height: 500}}>
                <PropagateLoader color="rgb(17, 24, 39)" loading={isLoading} size={20} speedMultiplier={1.5}/>
            </div>
        );
    }

    return (
        <div
            className={`nc-PageSingleGallery pt-8 lg:pt-16 ${className}`}
            data-nc-id="PageSingleGallery"
        >
            <Helmet>
                <title>Hong Kong Dreaming | Moja książka</title>
            </Helmet>
            <header className="container rounded-xl">
                <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-2 my-10">
                    <div
                        className="aspect-h-1 aspect-w-1 col-span-1 row-span-2 relative rounded-xl overflow-hidden"
                    >
                        <ReactPlayer
                            url={book?.videoUrl}
                            className="absolute inset-0"
                            style={{borderRadius: 18, overflow: "hidden"}}
                            playing={isPlay}
                            loop={true}
                            width="100%"
                            height="100%"
                            muted={true}
                            onReady={() => {
                                setIsPlay(true)
                            }}
                            config={{
                                file: {
                                    attributes: {
                                        "object-fit": "cover"
                                    }
                                }
                            }}
                        />
                    </div>
                    <div
                        className="md:col-span-2 col-span-1 row-span-2 relative rounded-xl overflow-hidden cursor-pointer"
                        onClick={() => handleOpenModal(0)}
                    >
                        <NcImage
                            containerClassName="absolute inset-0"
                            className="object-cover w-full h-full rounded-xl"
                            src={book?.photoUrls[0]}
                        />
                        <div
                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                    </div>
                    {book?.photoUrls.filter((_, i) => i >= 1 && i < 3).map((item, index) => (
                        <div
                            key={index}
                            className={`relative rounded-xl overflow-hidden ${
                                index >= 2 ? "hidden sm:block" : ""
                            }`}
                        >
                            <NcImage
                                containerClassName="aspect-w-6 aspect-h-5"
                                className="object-cover w-full h-full rounded-xl "
                                src={item || ""}
                            />

                            {/* OVERLAY */}
                            <div
                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                onClick={() => handleOpenModal(index + 1)}
                            />
                        </div>
                    ))}
                    <div
                        className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-full bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                        onClick={() => handleOpenModal(0)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                            />
                        </svg>
                        <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
                    </div>
                </div>
            </header>

            <ListingImageGallery
                isShowModal={isOpen}
                onClose={handleCloseModal}
                // images={IMAGES_GALLERY.map((item, index) => {
                //     return {
                //         id: index,
                //         url: item,
                //     };
                // })}
                images={book?.photoUrls.map((item, index) => {
                    return {
                        id: index,
                        url: item,
                    };
                }) || []}
            />

            {/* FEATURED IMAGE */}
            <div className=""></div>
            {/* SINGLE MAIN CONTENT */}
            <div className="container pb-10">
                <div className="nc-SingleContent space-y-10">
                    {/* ENTRY CONTENT */}
                    <div
                        id="single-entry-content"
                        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark dark:text-neutral-300"
                    >
                        {/* THIS IS THE DEMP CONTENT */}
                        {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
                        <>
                            {/* THIS IS THE DEMP CONTENT */}
                            {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
                            {contentfulDocumentToReactComponents(book?.description)}
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookPage;
