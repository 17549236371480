import React, {FC, useEffect, useState} from "react";
import Card2 from "components/Card2/Card2";
import {PostDataType, TaxonomyType} from "data/types";
import Card6 from "components/Card6/Card6";
import HeaderFilter from "./HeaderFilter";
import {ContentfulBlogRepository} from "../../utils/blogRepository";
import {client} from "../../utils/amplitudeClient";

export interface SectionMagazine1Props {
    posts: PostDataType[];
    heading?: string;
    className?: string;
}

export interface Tab {
    name: string;
    slug: string;
}

const SectionMagazine1: FC<SectionMagazine1Props> = ({
     posts,
     heading = "Odkrywaj Hongkong Dreaming 🎈 ",
     className = "",
 }) => {
    const [tabActive, setTabActive] = useState<Tab>();
    const [tagPosts, setTagPosts] = useState<PostDataType[]>([]);

    const [tags, setTags] = useState<TaxonomyType[]>([]);

    const loadPosts = (tab: Tab) => {
        ContentfulBlogRepository.findPostsByTag(tab.slug, 1, 5, "-fields.publishedAt").then((posts) => {
            setTagPosts(posts)
        }).catch((error) => console.error(error))
    }

    useEffect(() => {
        ContentfulBlogRepository.findAllTags(4, "fields.order")
            .then((entries: TaxonomyType[]) => {
                setTags(entries);
                const tab = {name: entries[0].name, slug: entries[0].id.toString()};
                setTabActive(tab);
                loadPosts(tab);
            })
            .catch((error: any) => console.log(error));
    }, [])

    const handleTabClick = (item: string) => {
        if (item === tabActive?.name) {
            return;
        }

        const tag = tags.find(value => (value.name === item))
        if (tag !== undefined) {
            client.logEvent("Home page tag clicked", {
                project: "hong-kong-dreaming",
                tagId: tag.id,
            });
            const tab = {name: tag.name, slug: tag.id.toString()}
            setTabActive(tab);
            loadPosts(tab)
        }
    }

    return (
        <div className={`nc-SectionMagazine1 ${className}`}>
            <HeaderFilter
                tabActive={tabActive?.name ?? ""}
                heading={heading}
                tabs={tags.map(entry => entry.name)}
                onTabClick={handleTabClick}
            />
            {!tagPosts.length && <span>Nic nie znaleziono!</span>}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8">
                {tagPosts[0] && <Card2 size="large" post={tagPosts[0]}/>}
                <div className="grid gap-6 md:gap-8">
                    {tagPosts
                        .filter((_, i) => i < 4 && i > 0)
                        .map((item, index) => (
                            <Card6 key={index} post={item}/>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SectionMagazine1;
