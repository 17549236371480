import React, {FC} from "react";
import Heading from "components/Heading/Heading";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import Button from "../Button/Button";
import {ArrowRightIcon} from "@heroicons/react/24/solid";
import {useNavigate} from "react-router-dom";

export interface HeaderFilterProps {
    tabActive: string;
    tabs: string[];
    heading: string;
    onTabClick: (item: string) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
   tabActive,
   tabs = ["Wszystkie", "Garden", "Fitness", "Design"],
   heading = "🎈 Latest Articles", onTabClick
}) => {
    const router = useNavigate();

    return (
        <div className="flex flex-col mb-8 relative">
            <Heading desc={"Najnowsze wpisy"}>{heading}</Heading>
            <div className="flex justify-between">
                <Nav
                    className="sm:space-x-2"
                    containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base"
                >
                    {tabs.map((item, index) => (
                        <NavItem
                            key={index}
                            isActive={tabActive === item}
                            onClick={() => onTabClick(item)}
                        >
                            {item}
                        </NavItem>
                    ))}
                </Nav>
                <Button className="!hidden md:!flex" pattern="white" sizeClass="px-6" onClick={() => {
                    router("/szukaj")
                }}>
                    <span>Pokaż wszystkie</span>
                    <ArrowRightIcon className="w-6 h-6 ml-3"/>
                </Button>
            </div>
        </div>
    );
};

export default HeaderFilter;
