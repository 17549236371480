import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {BLOCKS, Document, INLINES} from "@contentful/rich-text-types";
import {ReactNode} from "react";

const contentfulDocumentToReactComponents = (content?: Document): ReactNode => {
    if (!content) {
        return null;
    }

    return documentToReactComponents(content, {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node, children): ReactNode => {
                const contentType = node.data.target.fields.file.contentType;
                if (contentType === "image/jpeg" || contentType === "image/png" || contentType === "image/gif" || contentType === "image/webp") {
                    return (
                        <img
                            alt={node.data.target.fields.title}
                            src={node.data.target.fields.file.url}
                            title={node.data.target.fields.title}
                        />
                    );
                }
                if (contentType === "video/mp4" || contentType === "video/quicktime" || contentType === "video/ogg" || contentType === "video/webm") {
                    return (
                        <video controls>
                            <source src={node.data.target.fields.file.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    );
                }
            },
            [INLINES.HYPERLINK]: (node, children): ReactNode => {
                if((node.data.uri).includes("player.vimeo.com/video")){
                    return <iframe width="100%" height="450px" title={node.data.title} src={node.data.uri} frameBorder="0" allowFullScreen></iframe>
                } else if((node.data.uri).includes("youtube.com/embed")) {
                    return <iframe width="100%" height="450px" title={node.data.title} src={node.data.uri} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen></iframe>
                } else {
                    return <a target="_blank" rel="noreferrer" href={node.data.uri}>{children}</a>
                }
            },
        }
    });
}

export { contentfulDocumentToReactComponents }
