import React, {useEffect, useMemo, useState} from "react";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import {contentfulClient} from "../utils/contentfulClient";
import {NavItemType} from "../components/Navigation/NavigationItem";

const SiteHeader = () => {
  const [isLogged] = useState(false);
  const [navigation, setNavigation] = useState<NavItemType[]>([]);

  useEffect(() => {
    contentfulClient.getEntries({
      content_type: "navigation",
      limit: 10,
      // @ts-ignore
      order: "fields.order",
    }).then((response) => {
      const navigationItems: NavItemType[] = response.items.map((entry: any) => {
        return {
          id: entry.sys.id,
          href: entry.fields.href,
          name: entry.fields.name,
        };
      });
      setNavigation(navigationItems);
    }).catch(error => console.log(error));
  }, []);

  const headerComponent = useMemo(() => {
    if (isLogged) {
      return <HeaderLogged navigation={navigation} />;
    }
    return <Header navigation={navigation} />;
  }, [isLogged, navigation]);

  return <>{headerComponent}</>;
};
export default SiteHeader;
