import {Book, PostAuthorType, PostDataType, randomColor, TaxonomyType} from "../data/types";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";
import {readingTime} from "reading-time-estimator";
import {contentfulClient} from "./contentfulClient";
import {nameToIcon, SocialType} from "../components/SocialsShare/SocialsShare";

export interface SearchResult {
    posts: PostDataType[],
    pages: number,
    total: number
}

export interface BlogRepository {
    findAllTags(limit: number, orderBy: string): Promise<TaxonomyType[]>;
    findAllPosts(page: number, limit: number, orderBy: string): Promise<SearchResult>;
    findPostBySlug(slug: string): Promise<PostDataType|null>;
    findPostsByTag(tag: string, page: number, limit: number, orderBy: string): Promise<PostDataType[]>;
    findPostsByTags(tags: string[], page: number, limit: number, orderBy: string): Promise<PostDataType[]>;
    findFeaturedPosts(place: string, page: number, limit: number, orderBy: string): Promise<PostDataType[]>;
    searchPosts(query: string, page: number, limit: number, orderBy: string): Promise<SearchResult>;
    searchPostsWithTags(query: string, tags: string[], page: number, limit: number, orderBy: string): Promise<SearchResult>;
    findAuthorBySlug(slug: string): Promise<PostAuthorType|null>;
    findAuthorInMedia(page: number, limit: number, orderBy: string): Promise<PostDataType[]>;
    findAuthorBookBySlug(slug: string): Promise<Book|null>;
    findAllSocials(): Promise<SocialType[]>;
    mapPost(entry: any): PostDataType;
    mapTag(entry: any): TaxonomyType;
    mapAuthor(entry: any): PostAuthorType;
    mapAuthorInMedia(entry: any): PostDataType;
    mapBook(entry: any): Book;
    mapSocial(entry: any): SocialType;
}

const ContentfulBlogRepository: BlogRepository = {
    findAllSocials: async (): Promise<SocialType[]> => {
        try {
            const entries = await contentfulClient.getEntries({
                content_type: "social"
            });

            return entries.items.map(ContentfulBlogRepository.mapSocial);
        } catch (error) {
            console.log(error);
        }

        return [];
    },
    findAuthorBookBySlug: async (slug: string): Promise<Book | null> => {
        try {
            const entries = await contentfulClient.getEntries({
                "fields.slug": slug,
                content_type: "authorBook",
                limit: 1,
                // @ts-ignore
                order: "sys.createdAt",
                include: 10
            });
            const books = entries.items.map(ContentfulBlogRepository.mapBook)

            return books.length > 0 ? books[0] : null;
        } catch (error) {
            console.log(error);

            return null;
        }
    },
    findAuthorInMedia: async (page: number, limit: number, orderBy: string): Promise<PostDataType[]> => {
        try {
            const entries = await contentfulClient.getEntries({
                content_type: "authorInMedia",
                limit: limit,
                skip: (page - 1) * limit,
                // @ts-ignore
                order: orderBy,
                include: 10
            })

            return entries.items.map(ContentfulBlogRepository.mapAuthorInMedia)
        } catch (e) {
            console.error(e)
            return [];
        }
    },
    findAuthorBySlug: async (slug: string): Promise<PostAuthorType | null> => {
        const entries = await contentfulClient.getEntries({
            "fields.slug": slug,
            content_type: "author"
        });
        const authors = entries.items.map(ContentfulBlogRepository.mapAuthor)

        return authors.length > 0 ? authors[0] : null;
    },
    findAllTags: async (limit: number, orderBy: string): Promise<TaxonomyType[]> => {
        try {
            const entries = await contentfulClient.getEntries({
                content_type: "tag",
                limit: limit,
                // @ts-ignore
                order: orderBy
            })

            return entries.items.map(ContentfulBlogRepository.mapTag);
        } catch (e) {
            console.error(e)
            return [];
        }
    },
    findFeaturedPosts: async (place: string, page: number, limit: number, orderBy: string): Promise<PostDataType[]>  => {
        try {
            const entries = await contentfulClient.getEntries({
                "fields.featured": place,
                content_type: "post",
                limit: limit,
                skip: (page - 1) * limit,
                // @ts-ignore
                order: orderBy,
                include: 10
            })

            return entries.items.map(ContentfulBlogRepository.mapPost)
        } catch (e) {
            console.error(e)
            return [];
        }
    },
    findAllPosts: async (page: number, limit: number, orderBy: string): Promise<SearchResult> => {
        try {
            const entries = await contentfulClient.getEntries({
                content_type: "post",
                limit: limit,
                skip: (page - 1) * limit,
                // @ts-ignore
                order: orderBy,
                include: 10
            })

            return {
                posts: entries.items.map(ContentfulBlogRepository.mapPost),
                pages: Math.ceil(entries.total / limit),
                total: entries.total
            };
        } catch (e) {
            console.error(e)

            return {posts: [], pages: 0, total: 0};
        }
    },
    findPostBySlug: async (slug: string): Promise<PostDataType|null> => {
        try {
            const entries = await contentfulClient.getEntries({
                "fields.slug": slug,
                content_type: "post",
                limit: 1,
                // @ts-ignore
                include: 10
            })
            const posts = entries.items.map(ContentfulBlogRepository.mapPost)

            return posts.length > 0 ? posts[0] : null;
        } catch (e) {
            console.error(e)
            return null;
        }
    },
    findPostsByTag: async (tag: string, page: number, limit: number, orderBy: string): Promise<PostDataType[]> => {
        try {
            const entries = await contentfulClient.getEntries({
                // @ts-ignore
                "metadata.tags.sys.id[in]": tag,
                content_type: "post",
                limit: limit,
                skip: (page - 1) * limit,
                // @ts-ignore
                order: orderBy,
                include: 10
            })

            return entries.items.map(ContentfulBlogRepository.mapPost)
        } catch (e) {
            console.error(e)
            return [];
        }
    },
    findPostsByTags: async (tags: string[], page: number, limit: number, orderBy: string): Promise<PostDataType[]> => {
        try {
            const entries = await contentfulClient.getEntries({
                // @ts-ignore
                "metadata.tags.sys.id[in]": tags.join(","),
                content_type: "post",
                limit: limit,
                skip: (page - 1) * limit,
                // @ts-ignore
                order: orderBy,
                include: 10
            })

            return entries.items.map(ContentfulBlogRepository.mapPost)
        } catch (e) {
            console.error(e)
            return [];
        }
    },
    searchPosts: async (query: string, page: number, limit: number, orderBy: string): Promise<SearchResult> => {
        try {
            const entries = await contentfulClient.getEntries({
                query: query,
                content_type: "post",
                limit: limit,
                skip: (page - 1) * limit,
                // @ts-ignore
                order: orderBy,
                include: 10,
            })

            return {
                posts: entries.items.map(ContentfulBlogRepository.mapPost),
                pages: Math.ceil(entries.total / limit),
                total: entries.total
            };
        } catch (e) {
            console.error(e);

            return {posts: [], pages: 0, total: 0};
        }
    },
    searchPostsWithTags: async (query: string, tags: string[], page: number, limit: number, orderBy: string): Promise<SearchResult> => {
        try {
            const entries = await contentfulClient.getEntries({
                query: query,
                // @ts-ignore
                "metadata.tags.sys.id[in]": tags.join(","),
                content_type: "post",
                limit: limit,
                skip: (page - 1) * limit,
                // @ts-ignore
                order: orderBy,
                include: 10
            })

            return {
                posts: entries.items.map(ContentfulBlogRepository.mapPost),
                pages: Math.ceil(entries.total / limit),
                total: entries.total
            };
        } catch (e) {
            console.error(e);

            return {posts: [], pages: 0, total: 0};
        }
    },
    mapTag: (entry: any): TaxonomyType => {
        if (entry.fields === undefined) {
            return {
                id: entry.sys.id,
                name: entry.sys.id,
                color: randomColor(entry.sys.id),
                href: "#",
                taxonomy: "category"
            }
        }

        return {
            id: entry.fields.slug,
            name: entry.fields.title,
            color: entry.fields.color,
            href: "#",
            taxonomy: "category",
            thumbnail: entry.fields.thumbnail?.fields.file.url || undefined,
            count: entry.fields.count || 0
        }
    },
    mapPost: (entry: any): PostDataType => {
        let date = ""
        if (entry.fields.publishedAt !== undefined) {
            date = (new Date(entry.fields.publishedAt)).toLocaleDateString('pl-PL');
        } else {
            date = (new Date(entry.sys.createdAt)).toLocaleDateString('pl-PL');
        }
        const content = documentToHtmlString(entry.fields.content)
        const stats = readingTime(content)

        return {
            id: entry.sys.id,
            title: entry.fields.title,
            featuredImage: entry.fields.featuredImage.fields.file.url,
            href: "/"+entry.fields.slug,
            disqusUrl: entry.fields.disqusUrl,
            author: {
                id: entry.fields.author.sys.id,
                firstName: entry.fields.author.fields.firstName,
                lastName: entry.fields.author.fields.lastName,
                displayName: entry.fields.author.fields.firstName + " " + entry.fields.author.fields.lastName,
                avatar: entry.fields.author.fields.avatar.fields.file.url,
                bgImage: entry.fields.author.fields.backgroundImage.fields.file.url,
                count: 0,
                desc: entry.fields.author.fields.description,
                jobName: "",
                href: "/autorzy/" + entry.fields.author.fields.slug,
            },
            date: date,
            desc: entry.fields.description,
            content: entry.fields.content,
            like: {
                count: 0,
                isLiked: false
            },
            bookmark: {
                count: 0,
                isBookmarked: false,
            },
            commentCount: 0,
            viewdCount: 0,
            readingTime: Math.ceil(stats.minutes),
            postType: entry.fields.type,
            categories: entry.fields.tags?.map(ContentfulBlogRepository.mapTag) || [],
            seoTags: entry.fields?.seoTags || []
        }
    },
    mapAuthorInMedia(entry: any): PostDataType {
        let date = ""
        if (entry.fields.publishedAt !== undefined) {
            date = (new Date(entry.fields.publishedAt)).toLocaleDateString('pl-PL');
        } else {
            date = (new Date(entry.sys.createdAt)).toLocaleDateString('pl-PL');
        }

        return {
            id: entry.sys.id,
            title: entry.fields.title,
            featuredImage: entry.fields.featuredImage.fields.file.url,
            href: entry.fields.href,
            disqusUrl: entry.fields.disqusUrl,
            author: {
                id: entry.fields.author.sys.id,
                firstName: entry.fields.author.fields.firstName,
                lastName: entry.fields.author.fields.lastName,
                displayName: entry.fields.author.fields.firstName + " " + entry.fields.author.fields.lastName,
                avatar: entry.fields.author.fields.avatar.fields.file.url,
                bgImage: entry.fields.author.fields.backgroundImage.fields.file.url,
                count: 0,
                desc: entry.fields.author.fields.description,
                jobName: "",
                href: "/autorzy/" + entry.fields.author.fields.slug,
            },
            date: date,
            desc: entry.fields.description,
            content: entry.fields.content,
            like: {
                count: 0,
                isLiked: false
            },
            bookmark: {
                count: 0,
                isBookmarked: false,
            },
            commentCount: 0,
            viewdCount: 0,
            readingTime: 0,
            postType: entry.fields.type,
            categories: [],
            seoTags: []
        }
    },
    mapAuthor(entry: any): PostAuthorType {
        return {
            id: entry.sys.id,
            firstName: entry.fields.firstName,
            lastName: entry.fields.lastName,
            displayName: entry.fields.firstName + " " + entry.fields.lastName,
            avatar: entry.fields.avatar.fields.file.url,
            bgImage: entry.fields.backgroundImage.fields.file.url,
            count: 0,
            desc: entry.fields.description,
            jobName: "",
            href: "/autorzy/" + entry.fields.slug,
        }
    },
    mapBook(entry: any): Book {
        return {
            title: entry.fields.title,
            description: entry.fields.description,
            photoUrls: entry.fields.photos.map((image: any) => image.fields.file.url),
            videoUrl: entry.fields.video.fields.file.url
        }
    },
    mapSocial(entry: any): SocialType {
        return {
            id: entry.sys.id,
            name: entry.fields.name,
            icon: nameToIcon(entry.fields.name),
            href: entry.fields.link
        }
    }
}

export {ContentfulBlogRepository}
