import React, {useEffect, useState} from "react";
import Input from "components/Input/Input";
import NcImage from "components/NcImage/NcImage";
import Card11 from "components/Card11/Card11";
import ButtonCircle from "components/Button/ButtonCircle";
import {useLocation} from "react-router-dom";
import {ContentfulBlogRepository, SearchResult} from "../../../utils/blogRepository";
import {ArrowSmallRightIcon} from "@heroicons/react/24/outline";
import {TaxonomyType} from "../../../data/types";
import Heading from "../../../components/Heading/Heading";
import {PropagateLoader} from "react-spinners";
import {Helmet} from "react-helmet-async";
import {client} from "../../../utils/amplitudeClient";

export interface SearchState {
  searchPhrase: string,
  filters: {
    tags: string[]
  },
  order: string,
  pagination: {
    limit: number,
    page: number,
  }
}

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PageSearch = () => {
  const [query, setQuery] = useState<SearchState>({
    searchPhrase: useQuery().get("q") || "",
    filters: {
      tags: []
    },
    order: "-fields.publishedAt",
    pagination: {
      page: 1,
      limit: 12
    }
  });

  const [searchResult, setSearchResult] = useState<SearchResult>({
    posts: [],
    pages: 0,
    total: 0
  });

  const [tags, setTags] = useState<TaxonomyType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    client.logEvent("Search page viewed", {
      project: "hong-kong-dreaming",
      searchPhrase: query.searchPhrase,
    });
    if (query.filters.tags.length === 0) {
      ContentfulBlogRepository
        .searchPosts(query.searchPhrase, query.pagination.page, query.pagination.limit, "-fields.publishedAt")
        .then((result) => {
          setSearchResult(result);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setSearchResult({posts: [], pages: 0, total: 0});
          setIsLoading(false);
        });
    } else {
      ContentfulBlogRepository
        .searchPostsWithTags(
          query.searchPhrase,
          query.filters.tags,
          query.pagination.page,
          query.pagination.limit,
          "-fields.publishedAt"
        )
        .then((result) => {
          setSearchResult(result);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setSearchResult({posts: [], pages: 0, total: 0});
          setIsLoading(false);
        });
      client.logEvent("Filtered by", {tags: query.filters.tags.join(",")});
    }
    if (query.searchPhrase !== "") {
      client.logEvent("Searched for", {query: query.searchPhrase});
    }
  }, [query]);

  useEffect(() => {
    ContentfulBlogRepository
        .findAllTags(100, "fields.order")
        .then((entries: TaxonomyType[]) => {
          setTags(entries);
        })
        .catch((error: any) => console.log(error));
  }, []);

  const handlePaginationSwitch = (page: number) => {
    client.logEvent("Search page pagination switched on search", {
      project: "hong-kong-dreaming",
      currentPage: query.pagination.page,
      newPage: page
    });
    const commentDiv = document.getElementById('section-search-results');
    if (commentDiv) {
      commentDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    const newQuery = {
      ...query,
    }
    newQuery.pagination.page = page
    setQuery(newQuery)
  }

  const handleTagClick = (tag: TaxonomyType) => {
    client.logEvent("Search page tag clicked", {
      project: "hong-kong-dreaming",
      tagId: tag.id,
    });
    const newQuery = {
      ...query
    }

    if (newQuery.filters.tags.includes(tag.id.toString())) {
      newQuery.filters.tags = newQuery.filters.tags.filter(element => element !== tag.id)
    } else {
      newQuery.filters.tags.push(tag.id.toString())
    }
    setQuery(newQuery)
  }

  const renderTags = (tags: TaxonomyType[], query: SearchState) => {
    return <div
        className={`nc-WidgetTags rounded-3xl overflow-hidden`}
        data-nc-id='WidgetTags'
    >
      <Helmet>
        <title>Hong Kong Dreaming | Szukaj</title>
      </Helmet>
      <div
          className={`nc-WidgetHeading1 flex items-center justify-between p-4 xl:p-5 border-b border-neutral-200 dark:border-neutral-700`}
          data-nc-id='WidgetHeading1'
      >
        <h2 className='text-lg text-neutral-900 dark:text-neutral-100 font-semibold flex-grow'>
          {'Kategorie'}
        </h2>
      </div>
      <div className='flex flex-wrap p-4 xl:p-5 space-x-1 space-y-1'>
        {tags.map((tag) => (
            <button
                key={tag.id}
                className={`leading-none inline-block bg-white text-sm text-neutral-600 py-2 px-3 rounded-lg border border-neutral-100 md:py-2.5 md:px-4 dark:text-neutral-100 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 ${query.filters.tags.includes(tag.id.toString()) ? "dark:text-secondary-6000 text-primary-6000" : ""}`}
                onClick={() => {handleTagClick(tag)}}
            >
              {`${tag.name}`}
              {tag.count !== 0 && (
                  <span className='text-xs font-normal'> ({tag.count})</span>
              )}
            </button>
        ))}
      </div>
    </div>;
  };

  const renderPagination = (searchResults: SearchResult, query: SearchState) => {
    const pages = []

    for(let i = 1; i <= searchResults.pages; i++) {
      if (query.pagination.page === i) {
        pages.push(
            <span
                key={i}
                className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white`}
                onClick={() => {handlePaginationSwitch(i)}}
                style={{userSelect: 'none'}}
                data-page={i}
            >
            {i}
          </span>
        )
      } else {
        pages.push(
            <span
                key={i}
                className={`hover:cursor-pointer inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700`}
                onClick={() => {handlePaginationSwitch(i)}}
                style={{userSelect: 'none'}}
                data-page={i}
            >
            {i}
          </span>
        )
      }
    }

    if (pages.length === 1) {
      return <nav/>
    }

    return <nav
        className={`nc-Pagination inline-flex space-x-1 text-base font-medium`}
    >
      {pages}
    </nav>
  };

  return (
      <div className={`nc-PageSearch`}>
        <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
          <div
              className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-9 lg:aspect-h-5 overflow-hidden z-0">
            <NcImage
                alt="search"
                fill
                containerClassName="absolute inset-0"
                src="https://images.pexels.com/photos/2138922/pexels-photo-2138922.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                className="object-cover w-full h-full"
                sizes="(max-width: 1280px) 100vw, 1536px"
            />
          </div>
          {/* CONTENT */}
          <div className="relative container -mt-20 lg:-mt-48">
            <div
                className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex items-center">
              <header className="w-full max-w-3xl mx-auto text-center flex flex-col items-center">
                <h2 className="text-2xl sm:text-4xl font-semibold">{query.searchPhrase}</h2>
                {isLoading ? (
                    <div className="flex flex-row justify-center mt-24" style={{height: 10}}>
                      <PropagateLoader color="rgb(17, 24, 39)" loading={isLoading} size={20} speedMultiplier={1.5}/>
                    </div>
                ) : (
                    <span className="block text-xs sm:text-sm mt-4 text-neutral-500 dark:text-neutral-300">
                      Znaleziono{" "}
                      <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                        {searchResult.total}
                      </strong>{" "}
                      {searchResult.total === 1 ? "wpis" : ""}{" "}
                      {searchResult.total > 1 && searchResult.total < 5 ? "wpisy" : ""}{" "}
                      {searchResult.total >= 5 ? "wpisów" : ""}{" "}
                      {query.searchPhrase !== "" &&
                      <span>
                        dla{" "}
                        <strong className="font-medium text-neutral-800 dark:text-neutral-100">
                          {query.searchPhrase}
                        </strong>
                      </span>}
                    </span>
                )}
                <form
                    className="relative w-full mt-8 sm:mt-11 text-left"
                    method="get"
                >
                  <label
                      htmlFor="search-input"
                      className="text-neutral-500 dark:text-neutral-300"
                  >
                    <span className="sr-only">Search all icons</span>
                    <Input
                        name="q"
                        id="search-input"
                        type="search"
                        placeholder="Napisz i wciśnij enter"
                        sizeClass="pl-14 py-5 pr-5 md:pl-16"
                        defaultValue={query.searchPhrase}
                    />
                    <ButtonCircle
                        className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                        size=" w-11 h-11"
                        type="submit"
                    >
                      <ArrowSmallRightIcon className="w-6 h-6"/>
                    </ButtonCircle>
                    <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                      <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                      ></path>
                    </svg>
                  </span>
                  </label>
                </form>
              </header>
            </div>
          </div>
        </div>

        <div id={"section-search-results"} className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
          <main>
            {/* TABS FILTER */}
            <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
              <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
              <Heading desc={""}>Wyniki wyszukiwania 🎉</Heading>
              {/*<div className="flex justify-end">*/}
              {/*  <ArchiveFilterListBox lists={FILTERS} />*/}
              {/*</div>*/}
            </div>
            {renderTags(tags, query)}
            {/* LOOP ITEMS */}
            {/* LOOP ITEMS POSTS */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:gap-8 mt-8 lg:mt-10">
              {searchResult.posts.map((post) => (
                  <Card11 key={post.id} post={post}/>
              ))}
            </div>

            {/* PAGINATION */}
            <div
                className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              {renderPagination(searchResult, query)}
            </div>
          </main>
        </div>
      </div>
  );
};

export default PageSearch;
