import React, { FC } from "react";
import MainNav2 from "./MainNav2";
import {NavItemType} from "../Navigation/NavigationItem";

export interface HeaderProps {
    navigation: NavItemType[];
}

const Header: FC<HeaderProps> = ({navigation = []}) => {
  return (
    <div className="nc-Header sticky top-0 w-full z-40">
      <MainNav2 data={navigation} />
    </div>
  );
};

export default Header;
