import React, { FC } from "react";
import MainNav2Logged from "./MainNav2Logged";
import {NavItemType} from "../Navigation/NavigationItem";

export interface HeaderLoggedProps {
    navigation: NavItemType[];
}

const HeaderLogged: FC<HeaderLoggedProps> = ({navigation = []}) => {
  return (
    <div className="nc-HeaderLogged sticky top-0 w-full z-40">
      <MainNav2Logged data={navigation} />
    </div>
  );
};

export default HeaderLogged;
