import React, {useEffect, useState} from "react";
import {PostAuthorType} from "data/types";
import NcImage from "components/NcImage/NcImage";
import {useNavigate, useParams} from "react-router-dom";
import Avatar from "../../components/Avatar/Avatar";
import {contentfulDocumentToReactComponents} from "../../utils/contentfulDocumentToReactComponents";
import {PropagateLoader} from "react-spinners";
import {ContentfulBlogRepository} from "../../utils/blogRepository";
import {Helmet} from "react-helmet-async";
import {client} from "../../utils/amplitudeClient";

const PageAuthor = () => {
  const { slug } = useParams<{slug: string}>();
  const [isLoading, setIsLoading] = useState(true)
  const [author, setAuthor] = useState<PostAuthorType|null>(null)
  const router = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    client.logEvent("Author page viewed", {
      project: "hong-kong-dreaming",
    });
    const fetchAuthor = () => {
      setIsLoading(true)
      try {
        if (!slug) {
          setIsLoading(false)
          router("/404");
          return
        }
        ContentfulBlogRepository.findAuthorBySlug(slug)
          .then((author) => {
            if (!author) {
              setIsLoading(false)
              router("/404");
              return
            }
            setAuthor(author)
            setIsLoading(false)
          })
          .catch((error) => console.error("fetchAuthor -> error", error));
      } catch (error) {
        console.error("fetchAuthor -> error", error)
        setIsLoading(false)
      }
    }
    fetchAuthor();
  }, [slug, router]);

  return (
      <>
        {isLoading ? (
            <div className="flex flex-row justify-center mt-24" style={{height: 500}}>
              <PropagateLoader color="rgb(17, 24, 39)" loading={isLoading} size={20} speedMultiplier={1.5} />
            </div>
        ) : (
          <div className={`nc-PageAuthor`} data-nc-id="PageAuthor">
            <Helmet>
              <title>Hong Kong Dreaming | O mnie</title>
            </Helmet>
            {/* HEADER */}
            <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
              {author?.bgImage &&
                  <div
                      className="rounded-3xl relative aspect-w-16 aspect-h-16 sm:aspect-h-9 lg:aspect-h-6 overflow-hidden ">
                    <NcImage
                        containerClassName="absolute inset-0"
                        src={author.bgImage}
                        className="object-cover w-full h-full"
                    />
                  </div>
              }
              <div className="relative container -mt-20 lg:-mt-48 pb-10">
                <div
                    className="bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex flex-col sm:flex-row sm:items-center justify-center">
                  <Avatar
                      containerClassName="ring-4 ring-white dark:ring-0 shadow-2xl"
                      imgUrl={author?.avatar}
                      sizeClass="w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36"
                      radius="rounded-full"
                  />
                  <div className="mt-5 sm:mt-0 sm:ml-8 space-y-4 max-w-lg">
                    <h2 className="inline-block text-2xl sm:text-3xl md:text-4xl font-semibold">
                      {author?.displayName}
                    </h2>
                    <span className="block text-sm text-neutral-6000 dark:text-neutral-300 md:text-base">
                      {contentfulDocumentToReactComponents(author?.desc)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
  );
};

export default PageAuthor;
