import React, {FC, useEffect, useState} from "react";
import {PostDataType} from "../../data/types";
import {PropagateLoader} from "react-spinners";
import {ContentfulBlogRepository} from "../../utils/blogRepository";
import SectionMagazine10 from "../../components/Sections/SectionMagazine10";
import Heading from "../../components/Heading/Heading";
import {Helmet} from "react-helmet-async";
import {client} from "../../utils/amplitudeClient";

export interface PageAuthorProps {
    className?: string;
}

const InMediaPage: FC<PageAuthorProps> = ({ className = "" }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [mediaLinks, setMediaLinks] = useState<PostDataType[]>([])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        client.logEvent("Author in media page viewed", {
            project: "hong-kong-dreaming",
        });
        const loadMediaLinks = () => {
            setIsLoading(true);
            ContentfulBlogRepository.findAuthorInMedia(1, 20, "sys.createdAt")
                .then((mediaLinks) => {
                    setMediaLinks(mediaLinks);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error("fetchAuthorInMedia -> error", error);
                    setIsLoading(false);
                });
        }
        loadMediaLinks();
    }, []);

    return (
        <div className={`nc-PageAuthor`} data-nc-id="PageAuthor">
            <Helmet>
                <title>Hong Kong Dreaming | Kasia w mediach</title>
            </Helmet>
            <div className="relative overflow-hidden">
                <div className="container relative">
                    {isLoading ? (
                        <div className="flex flex-row justify-center mt-24" style={{height: 500}}>
                            <PropagateLoader color="rgb(17, 24, 39)" loading={isLoading} size={20} speedMultiplier={1.5} />
                        </div>
                    ): (
                        <>
                            <Heading className={"mt-20"} desc={"Znajdź mnie u innych"}>O mnie w mediach</Heading>
                            <SectionMagazine10 posts={mediaLinks.slice(0, 4)}  className={"mt-20"}/>
                            <SectionMagazine10 posts={mediaLinks.slice(4, 10)}  className={"mt-20 mb-24"}/>
                        </>
                )}
                </div>
            </div>
        </div>
    );
};

export default InMediaPage;
