import React, { FC } from "react";
import NavigationItem, {NavItemType} from "./NavigationItem";

interface Props {
  className?: string;
  data: NavItemType[];
}

const Navigation: FC<Props> = ({ className = "flex", data = [] }) => {
  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {data.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
};

export default Navigation;
