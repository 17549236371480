import React, {ReactNode} from "react";
import SingleContent from "../SingleContent";
import SingleRelatedPosts from "../SingleRelatedPosts";
import {PostDataType} from "../../../data/types";

const Layout = ({children, data, related, isLoading}: {
    children: ReactNode,
    data?: PostDataType,
    related?: PostDataType[],
    isLoading?: boolean
}) => {
    if (isLoading) {
        return (
            <div>
                {children}
            </div>
        );
    }

    return (
        <div>
            {children}

            {/* SINGLE MAIN CONTENT */}
            <div className="container mt-10">
                <SingleContent data={data}/>
            </div>

            {/* RELATED POSTS */}
            <SingleRelatedPosts relatedPosts={related}/>
        </div>
    );
};

export default Layout;
