import React, { FC, useEffect, useRef, useState } from "react";
import Tag from "components/Tag/Tag";
import SingleAuthor from "./SingleAuthor";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import {PostDataType} from "../../data/types";
import {contentfulDocumentToReactComponents} from "../../utils/contentfulDocumentToReactComponents";
import {useLocation} from "react-router-dom";
import {DiscussionEmbed} from "disqus-react";
import {useThemeMode} from "../../hooks/useThemeMode";

export interface SingleContentProps {
  data?: PostDataType
}

const SingleContent: FC<SingleContentProps> = ({data}) => {
  const {isDarkMode } = useThemeMode();
  const endedAnchorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLButtonElement>(null);
  //
  const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);
  //
  const location = useLocation();

  const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
    threshold: 0,
    root: null,
    rootMargin: "0%",
    freezeOnceVisible: false,
  });

  useEffect(() => {
    //  SCROLL TO COMMENT AREA
    if (location.hash !== "#comments") {
      return;
    }
    //
    if (location.hash === "#comments") {
      setTimeout(() => {
        const commentDiv = document.getElementById('comment-section');
        if (commentDiv) {
          commentDiv.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
    }
  }, [location]);

  useEffect(() => {
    const handleProgressIndicator = () => {
      const entryContent = contentRef.current;
      const progressBarContent = progressRef.current;

      if (!entryContent || !progressBarContent) {
        return;
      }

      const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let scrolled = (winScroll / totalEntryH) * 100;

      progressBarContent.innerText = scrolled.toFixed(0) + "%";

      if (scrolled >= 100) {
        setIsShowScrollToTop(true);
      } else {
        setIsShowScrollToTop(false);
      }
    };

    const handleProgressIndicatorHeadeEvent = () => {
      window?.requestAnimationFrame(handleProgressIndicator);
    };
    handleProgressIndicator();
    window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
    return () => {
      window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
    };
  }, []);

  const showLikeAndCommentSticky =
    !endedAnchorEntry?.intersectionRatio &&
    (endedAnchorEntry?.boundingClientRect.top || 0) > 0;

  return (
    <div className="relative">
      <div className="nc-SingleContent space-y-10">
        {/* ENTRY CONTENT */}
        <div
          id="single-entry-content"
          className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
          ref={contentRef}
        >
          {contentfulDocumentToReactComponents(data?.content)}
        </div>

        {/* TAGS */}
        <div className="max-w-screen-md mx-auto flex flex-wrap">
          {data?.categories.map((item) => (
            <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
          ))}
        </div>

        {/* AUTHOR */}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        <div className="max-w-screen-md mx-auto ">
          <SingleAuthor author={data?.author} />
        </div>

        {/* COMMENTS LIST */}
        <div className="max-w-screen-md mx-auto mb" id="comment-section">
          {data?.disqusUrl && isDarkMode && (
              <DiscussionEmbed
                  shortname='hongkongdreaming'
                  config={
                    {
                      language: 'pl-PL',
                      url: data.disqusUrl,
                      title: data.title,
                    }
                  }
              />
          )}
          {data?.disqusUrl && !isDarkMode && (
              <DiscussionEmbed
                  shortname='hongkongdreaming'
                  config={
                    {
                      language: 'pl-PL',
                      url: data.disqusUrl,
                      title: data.title,
                    }
                  }
              />
          )}
          <div ref={endedAnchorRef}></div>
        </div>
      </div>
      <div
        className={`sticky mt-8 bottom-8 z-40 justify-center ${
          showLikeAndCommentSticky ? "flex" : "hidden"
        }`}
      >
        <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
          <button
            className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${
              isShowScrollToTop ? "flex" : "hidden"
            }`}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ArrowUpIcon className="w-4 h-4" />
          </button>

          <button
            ref={progressRef}
            className={`w-9 h-9 items-center justify-center ${
              isShowScrollToTop ? "hidden" : "flex"
            }`}
            title="Go to top"
          >
            %
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleContent;
