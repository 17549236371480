import { SocialType } from "components/SocialsShare/SocialsShare";
import React, {FC, useEffect, useState} from "react";
import {ContentfulBlogRepository} from "../../utils/blogRepository";

export interface SocialsList1Props {
  className?: string;
}

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const [socials, setSocials] = useState<SocialType[]>([]);

  useEffect(() => {
    ContentfulBlogRepository.findAllSocials().then((socials) => {
        setSocials(socials);
    }).catch((error) => {
        console.error("Error loading socials", error);
    });
  }, []);

  const renderItem = (item: SocialType, index: number) => {
    if (item.name === "Email") {
      return (
        <a
          href={`mailto:${item.href}`}
          className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-3 "
          key={index}
        >
          <div dangerouslySetInnerHTML={{ __html: item.icon || "" }}></div>
          <span className="hidden lg:block text-sm">{item.name}</span>
        </a>
      );
    }
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-3 "
        key={index}
      >
        <div dangerouslySetInnerHTML={{ __html: item.icon || "" }}></div>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
