import Avatar from "components/Avatar/Avatar";
import { PostAuthorType } from "data/types";
import Link from "components/Link";
import React, { FC } from "react";
import ReactShowMoreText from "react-show-more-text";
import {contentfulDocumentToReactComponents} from "../../utils/contentfulDocumentToReactComponents";

export interface SingleAuthorProps {
  author?: PostAuthorType;
}

const SingleAuthor: FC<SingleAuthorProps> = ({ author  }) => {
  return (
    <div className="nc-SingleAuthor flex">
      <Link href={author?.href || "/"}>
        <Avatar
          imgUrl={author?.avatar}
          userName={author?.displayName}
          sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24"
        />
      </Link>
      <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
        <span className="text-xs text-neutral-400 uppercase tracking-wider">
          Napisane przez
        </span>
        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          <Link href={author?.href || "/"}>{author?.displayName}</Link>
        </h2>
        <ReactShowMoreText
            lines={3}
            expanded={false}
            more="Czytaj więcej"
            less="Zwiń"
            truncatedEndingComponent={"... "}
            className="block mt-1 text-sm text-neutral-500 sm:text-base dark:text-neutral-300"
            width={1000}
        >
          {contentfulDocumentToReactComponents(author?.desc)}
        </ReactShowMoreText>
      </div>
    </div>
  );
};

export default SingleAuthor;
